import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { RichText } from 'prismic-reactjs';

import Hero from 'components/Hero';
import { resolveSlice } from 'utils/slice-resolver.js';
import useSiteMetadata from 'hooks/useSiteMetadata';

import css from './product.module.scss';

const getImageClassName = valign => {
  if (valign === 'top') {
    return css.topImage;
  }

  if (valign === 'bottom') {
    return css.bottomImage;
  }

  return css.centerImage;
};

const Product = ({ data: { prismic }, location }) => {
  const { siteUrl } = useSiteMetadata();

  const { product } = prismic;
  const image = product.hero_imageSharp;
  const slices = product.body || [];

  const socialImage = product.meta_image
    ? product.meta_image.url
    : product.hero_image && product.hero_image.url;

  const imageClassName = getImageClassName(product.hero_image_vertical_align);
  const heroButton = product.hero_button_anchor
    ? {
        anchor: product.hero_button_anchor,
        label: product.hero_button_label,
      }
    : null;

  return (
    <>
      <Helmet>
        <title>{`SwissDeCode - ${RichText.asText(
          product.hero_title
        )} ${RichText.asText(product.hero_subtitle)}`}</title>
        <meta description={RichText.asText(product.hero_subtitle)} />
        <meta
          property="og:title"
          content={`${RichText.asText(product.hero_title)} ${RichText.asText(
            product.hero_subtitle
          )}`}
        />
        <meta
          property="og:description"
          content={RichText.asText(product.hero_description)}
        />
        <meta property="og:url" content={`${siteUrl}${location.pathname}`} />
        {socialImage && <meta property="og:image" content={socialImage} />}
        <meta
          property="twitter:description"
          content={RichText.asText(product.hero_description)}
        />
        <meta name="twitter:creator" content="@swissdecode" />
        {socialImage && <meta name="twitter:image" content={socialImage} />}
        <link rel="canonical" href={`${siteUrl}${location.pathname}`} />
      </Helmet>
      <Hero
        image={image ? image.childImageSharp.fluid : null}
        imageClassName={imageClassName}
        logo={product.hero_logo.url}
        logoClassName={css.heroLogo}
        title={product.hero_title}
        subtitle={product.hero_subtitle}
        description={product.hero_description}
        button={heroButton}
      />
      {slices.map(resolveSlice)}
    </>
  );
};

export default Product;

export const pageQuery = graphql`
  query ProductBySlug($uid: String!) {
    prismic {
      product(lang: "pt-pt", uid: $uid) {
        _meta {
          uid
        }
        hero_title
        hero_subtitle
        hero_image
        hero_imageSharp {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        hero_image_vertical_align
        hero_logo
        hero_description
        hero_button_label
        hero_button_anchor
        meta_image
        body {
          ... on PRISMIC_ProductBodyContent_section {
            primary {
              title
              type
            }
            fields {
              main
              icon
              content_text
              image
              imageSharp {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            type
          }
          ... on PRISMIC_ProductBodyFlow_section {
            type
            primary {
              title
              type
            }
            fields {
              icon
              name
              text
            }
          }
          ... on PRISMIC_ProductBodyMosaic_section {
            type
            primary {
              bottom_image
              description
              top_left_image
              title
              top_right_image
              type
              orientation
            }
            fields {
              button_label
              button_link {
                ... on PRISMIC__FileLink {
                  url
                }
              }
            }
          }
          ... on PRISMIC_ProductBodyContact_section {
            type
            primary {
              button_label
              title
              type
              inverted
              hubspot_portal_id
              hubspot_form_id
            }
          }
          ... on PRISMIC_ProductBodySolutions_section {
            type
            fields {
              solution_image
              solution_name
              solution_link {
                ... on PRISMIC_Product {
                  _meta {
                    uid
                  }
                  product_category {
                    ... on PRISMIC_Product_category {
                      _meta {
                        uid
                      }
                    }
                  }
                }
              }
            }
            primary {
              contact_label
              other_description
              type
              title
              other_title
            }
          }
          ... on PRISMIC_ProductBodyRelated_section {
            type
            related_primary: primary {
              title
            }
            fields {
              link {
                ... on PRISMIC_Blog_post {
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 520) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  title
                  _meta {
                    uid
                    type
                  }
                }
              }
            }
          }
          ... on PRISMIC_ProductBodyForm_section {
            type
            primary {
              form_section_id
              form_id
              form_label
              form_title
              form_id
              form_privacy
              form_success
              linkedin_conversion
            }
          }
          ... on PRISMIC_ProductBodyPoints_section {
            type
            points_primary: primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              label
              title
            }
            fields {
              bullet_point
            }
          }
          ... on PRISMIC_ProductBodyFaq_section {
            type
            fields {
              answer
              question
            }
            faq_primary: primary {
              label
              title
            }
          }
          ... on PRISMIC_ProductBodyPartners_section {
            type
            label
            primary {
              description
              title
            }
            fields {
              logo
            }
          }
        }
      }
    }
  }
`;
